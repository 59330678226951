import api from '@apiInstance';

export default {
  add(countryCode) {
    return api.post('country_codes', countryCode);
  },
  get(id) {
    return api.fetch('country_codes/' + id);
  },
  list(params) {
    return api.fetch('/country_codes', { params });
  },
  update(countryCode) {
    return api.patch('country_codes', countryCode);
  },
  delete(id) {
    return api.remove('country_codes', id);
  },
};
